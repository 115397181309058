import { css } from "styled-components";

// For the typography
export const fontSelect = type => {
  // This is the default font
  let fontSelected = "calibri";

  // Update font for headings
  if (type === "heading") {
    fontSelected = "arial";
  }

  return css`
    font-family: ${fontSelected};
  `;
};

// For the flex box
export const displayFlex = (justify, align) => {
  return css`
    display: flex;
    justify-content: ${justify};
    align-items: ${align};
  `;
};
export const flex = value => {
  return css`
    flex: ${value};
  `;
};

// For the background images
export const backgroundImageProperties = `
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

// For the hover/focus states
export const onHoverFocus = contentFunction => {
  return css`
    &:hover,
    &:focus {
      ${contentFunction};
    }
  `;
};

// For the smooth transition
export const transitionEffect = `
    transition: all 0.2s ease-in-out;
`;
