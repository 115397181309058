import styled from "styled-components";
import { fontSelect, displayFlex, flex } from "./style-variables";
import HeaderStyle from "./style-header";
import MainStyle from "./style-main";
import FooterStyle from "./style-footer";

export const MasterWrapper = styled.div`
  ${displayFlex("unset", "unset")};
  ${fontSelect("normal")}
  flex-direction: column;
  position: relative;
  color: black;
  font-size: 1rem;
  line-height: 2rem;
  min-height: 100vh;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${fontSelect("heading")};
  }

  h1 {
    display: inline-block;
    color: ${props => props.theme.green};
    font-size: 2.1rem;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.5rem;
    margin-top: 0;
  }
  h2 {
    font-size: 1.7rem;
  }
  h3 {
    font-size: 1.4rem;
    color: ${props => props.theme.crimson};
  }
  h4 {
    font-size: 1.2rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.8rem;
  }

  p,
  ul,
  ol {
    font-size: 1rem;
    line-height: 2rem;
  }

  a {
    color: black;
  }

  p > img {
    max-width: 100%;
  }

  // start of main
  // Max width on containers
  header, .main-wrapper .inner-wrapper, footer .inner-content-wrapper {
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;

    @media (max-width: 400px) {
      width: 95%;
    }
  }

  // Main wrapper
  #master-wrapper,
  footer {
    ${flex("1 auto")};
  }

  // Header
  ${HeaderStyle}
  // Content wrapper
  ${MainStyle}
  // Footer
  ${FooterStyle}
`;
