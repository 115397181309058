import React from "react";
import { TiSocialYoutube } from "react-icons/ti";

const Footer = () => {
  return (
    <footer>
      <div className="inner-wrapper">
        <div className="inner-content-wrapper">
          <div className="social-wrapper">
            <a href="https://www.youtube.com/channel/UCG0ynMZEQeWKicISMtvXsUQ" target="_blank" rel="noopener noreferrer">
              <span>Follow us on</span> <TiSocialYoutube className="footer-social-icon" />
            </a>
          </div>

          <div className="copyright">© {new Date().getFullYear()} Wheatgrass Nutrients</div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
