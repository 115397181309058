import { css } from "styled-components";
import { displayFlex } from "./style-variables";

export default css`
  footer {
    ${displayFlex("unset", "flex-end")};

    .inner-wrapper {
      background-color: black;
      width: 100%;

      .inner-content-wrapper {
        ${displayFlex("space-between", "flex-end")};
        color: white;

        a {
          ${displayFlex("unset", "center")};
          color: white;

          span {
            margin-right: 1rem;
          }
          svg {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
`;
