import { css } from "styled-components";
import { onHoverFocus, transitionEffect, displayFlex } from "./style-variables";

export default css`
  header {
    margin-bottom: 3rem;

    .inner-wrapper {
      ${displayFlex("unset", "unset")};
      flex-direction: column;

      #site-logo {
        background-size: contain;
        background-image: url("/images/logo-art.png");
        background-position: center;
        background-repeat: no-repeat;
        height: 210px;
        margin-bottom: 2rem;
        outline: none;

        @media (max-width: 740px) {
          background-image: url("/images/wheatgrasslogothumbnail.jpg");
          background-size: cover;
          height: 440px;
        }
        @media (max-width: 450px) {
          background-size: contain;
          height: 240px;
        }
      }

      .header-content-wrapper {
        border-bottom: 1px solid #ddd;
        padding-bottom: 1rem;

        #header-menu-wrapper {
          ${displayFlex("space-between", "center")};

          nav {
            ul {
              ${displayFlex("unset", "unset")};
              list-style-type: none;
              padding: 0;
              margin: 0;
              margin-right: 2rem;

              li a {
                ${transitionEffect};
                display: block;
                font-weight: 700;
                text-decoration: none;
                border-radius: 4px;
                padding: 0.3rem 0.7rem;
                margin: 0 0.2rem;
                outline: none;

                ${onHoverFocus(
                  () =>
                    css`
                      color: white;
                      background-color: black;
                    `
                )}
              }
            }

            @media (max-width: 830px) {
              display: none;
            }
          }
          #search-box {
            ${displayFlex("unset", "unset")};
            position: relative;
            width: 100%;
            max-width: 230px;
            margin-right: 0.5rem;

            form {
              ${displayFlex("unset", "unset")};
              width: 100%;

              input {
                border: none;
                width: 100%;
                padding: 0.7rem 0.5rem;
                border-radius: 4px;
                background-color: #ddd;
                outline: none;

                &:focus::placeholder {
                  opacity: 0;
                }
              }
            }
            svg {
              position: absolute;
              top: 10px;
              right: 12px;
            }
          }

          .menu-button {
            display: none;

            @media (max-width: 830px) {
              ${displayFlex("space-between", "center")};
              ${transitionEffect};
              height: 40px;
              border: none;
              padding: 1rem;
              border-radius: 4px;
              background-color: transparent;
              cursor: pointer;
              outline: none;

              span {
                margin-right: 0.7rem;
              }

              ${onHoverFocus(
                () =>
                  css`
                    color: white;
                    background-color: ${props => props.theme.green};
                  `
              )}
            }
          }
        }

        #menu ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          margin-top: 2rem;

          li {
            a {
              ${transitionEffect};
              display: block;
              text-decoration: none;
              padding: 0.7rem;
              margin-bottom: 0.1rem;
              border-radius: 4px;

              ${onHoverFocus(
                () =>
                  css`
                    color: white;
                    background-color: ${props => props.theme.green};
                  `
              )}
            }

            &:not(:last-child) {
              border-bottom: 1px solid #eee;
            }
          }
        }
      }
    }
  }
`;
