import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { ThemeProvider } from "styled-components";

import Header from "./header";
import Footer from "./footer";
import { MasterWrapper } from "./Global/styles";
import theme from "./Global/theme";

const Layout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const updateMenustate = prev => {
    setMenuOpen(!prev);
  };

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={theme}>
      <MasterWrapper>
        <section id="master-wrapper">
          <Header siteTitle={data.site.siteMetadata.title} menuOpen={menuOpen} updateMenustate={updateMenustate} />
          <section className="main-wrapper">
            <div className="inner-wrapper">{children}</div>
          </section>
        </section>

        <Footer />
      </MasterWrapper>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
