import { css } from "styled-components";
import { displayFlex, flex, backgroundImageProperties, onHoverFocus, transitionEffect } from "./style-variables";

export default css`
  .main-wrapper {
    display: flex;
    margin-bottom: 4rem;

    #articles-title {
      margin-bottom: 3rem;
    }

    article.card-styles {
      position: relative;
      margin: 0;
      margin-bottom: 2rem;

      a {
        ${displayFlex("unset", "unset")};
        ${transitionEffect};
        text-decoration: none;

        .card-image {
          ${flex("0 0 374px")}
          ${backgroundImageProperties};
          border-radius: 4px;
          height: auto;

          @media (max-width: 870px) {
            ${flex("0 0 240px")}
          }
        }

        .text-wrapper {
          ${flex("1 auto")}
          padding: 1rem 3rem;

          h3 {
            color: ${props => props.theme.green};
            margin-top: 0;
          }
        }

        ${onHoverFocus(function () {
          return css`
            background-color: #eee;`;
        })}
      }

      @media (max-width: 750px) {
        border-bottom: 1px solid #ddd;
        margin-bottom: 4rem;

        a {
          flex-direction: column;

          .card-image,
          .text-wrapper {
            ${flex("auto")}
          }

          .card-image {
            height: 300px;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
`;
