import { Link, useStaticQuery, graphql, navigate } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { IoIosSearch } from "react-icons/io";

const Header = ({ siteTitle, menuOpen, updateMenustate }) => {
  const data = useStaticQuery(graphql`
    {
      allMenuJson {
        edges {
          node {
            name
            slug
          }
        }
      }
    }
  `);

  return (
    <header>
      <div className="inner-wrapper">
        <Link to="/" id="site-logo" onClick={() => menuOpen && updateMenustate(true)} aria-label={siteTitle}></Link>

        <div className="header-content-wrapper">
          <div id="header-menu-wrapper">
            {/* For the main navigation */}
            <nav>
              <ul>
                {data.allMenuJson.edges.map(({ node }) => (
                  <li key={node.slug}>
                    <Link to={`/${node.slug}`}>{node.name}</Link>
                  </li>
                ))}
              </ul>
            </nav>

            {/* For the search box */}
            <div id="search-box">
              <form
                onSubmit={e => {
                  e.preventDefault();
                  navigate(`/?s=${e.target.query.value.toLowerCase()}`);
                }}
              >
                <input type="text" id="query" aria-label="Search" placeholder="Search our website.." />
              </form>

              <IoIosSearch />
            </div>

            {/* For the menu bar */}
            <button className="menu-button" onClick={() => updateMenustate(menuOpen)}>
              <span>Main Menu</span>
              {menuOpen ? <MdClose size={32} /> : <FiMenu size={32} />}
            </button>
          </div>

          {/* For the mobile menu items */}
          {menuOpen && (
            <div id="menu">
              <ul>
                {data.allMenuJson.edges.map(({ node }) => (
                  <li key={node.slug}>
                    <Link to={`/${node.slug}`}>{node.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
